import React from "react";

export default function AircraftStat({ value, label }) {
  return (
    <div
      className="stat"
      data-aos="fade-up"
      data-aos-easing="ease"
      data-aos-duration="2000"
    >
      <h1>{value}</h1>
      <p>{label}</p>
    </div>
  );
}
