import React from "react";

const sponsors = [
  {
    tier: "First Class",
    list: [
      {
        name: "Ford",
        image: require("../img/sponsors/ford.png"),
        url: "https://www.ford.com/",
      },
      {
        name: "Michigan Engineering",
        image: require("../img/sponsors/um_engin.png"),
        url: "https://engin.umich.edu/",
      },
      {
        name: "Boeing",
        image: require("../img/sponsors/boeing.png"),
        url: "https://www.boeing.com/",
      },
    ],
  },
  {
    tier: "Business Class",
    list: [
      {
        name: "General Atomics",
        image: require("../img/sponsors/general_atomics.jfif"),
        url: "https://www.ga.com/",
      },
      {
        name: "Northrop Grumman",
        image: require("../img/sponsors/northrop_grumman.png"),
        url: "https://www.northropgrumman.com/",
      },
      {
        name: "Lockheed Martin",
        image: require("../img/sponsors/lockheed_martin.png"),
        url: "https://www.lockheedmartin.com/en-us/index.html",
      },
      {
        name: "Esteco Academy",
        image: require("../img/sponsors/esteco_academy.png"),
        url: "https://www.esteco.com/academy",
      },
    ],
  },
  {
    tier: "Coach Class",
    list: [
      {
        name: "Michigan Mechanical Engineering",
        image: require("../img/sponsors/um_meche.png"),
        url: "https://me.engin.umich.edu/",
      },
      {
        name: "Michigan Aerospace Engineering",
        image: require("../img/sponsors/um_aero.jpg"),
        url: "https://aero.engin.umich.edu/",
      },
      {
        name: "Michigan Electrical and Computer Engineering",
        image: require("../img/sponsors/um_ece.png"),
        url: "https://ece.engin.umich.edu/",
      },

      {
        name: "Raytheon Technologies",
        image: require("../img/sponsors/raytheon_tech.png"),
        url: "https://www.rtx.com/",
      },
      {
        name: "Protocase",
        image: require("../img/sponsors/protocase.png"),
        url: "https://www.protocase.com/",
      },
      {
        name: "Blue Clover Devices",
        image: require("../img/sponsors/blue_clover_devices.png"),
        url: "https://www.bcdevices.com/",
      },
      {
        name: "Typhon UDX",
        image: require("../img/sponsors/typhon_udx.png"),
        url: "https://www.typhonudx.com/",
      },
      {
        name: "Blue Origin",
        image: require("../img/sponsors/blue_origin.png"),
        url: "https://www.blueorigin.com/",
      },
      {
        name: "Siemens",
        image: require("../img/sponsors/siemens.png"),
        url: "https://www.siemens.com/",
      },
    ],
  },
];

export default sponsors;
