import React from "react";

const members = [
  {
    name: "Administration",
    list: [
      {
        name: "Jackson Lawson",
        image: require("../img/members/jackson_lawson.jpg"),
        position: "Team Captain",
        linkedin: "https://www.linkedin.com/in/jackson-lawson",
        major: "Aerospace Engineering",
        minor: "",
        year: "2025",
      },
      {
        name: "Eric Sulewski",
        image: require("../img/members/eric_sulewski.jpg"),
        position: "Internal Vice President",
        linkedin: "https://www.linkedin.com/in/eric-sulewski-27ab17212/",
        major: "Masters in Aerospace Engineering",
        minor: "",
        year: "2025",
      },
      {
        name: "Alyssa Cheslek",
        image: require("../img/members/alyssa_cheslek.jpg"),
        position: "External Vice President",
        linkedin: "https://www.linkedin.com/in/alyssa-cheslek",
        major: "Aerospace Engineering",
        minor: "Electrical Engineering",
        year: "2026",
      },
    ],
  },
  {
    name: "Hi-Lift",
    list: [
      {
        name: "Katherine Rybkin",
        image: require("../img/members/katherine_rybkin.jpg"),
        position: "Hi-Lift Chief Engineer",
        linkedin: "www.linkedin.com/in/kirybkin",
        major: "Mechanical Engineering & Materials Science Engineering",
        year: "2026",
      },
      {
        name: "Gavin Verral",
        image: require("../img/members/gavin_verral.jpg"),
        position: "Hi-Lift Aerodynamics Lead",
        linkedin: "https://www.linkedin.com/in/gavin-verral-09752531b/",
        major: "Aerospace Engineering",
        year: "2026",
      },
      {
        name: "Cristina Erskine",
        image: require("../img/members/cristina_erskine.jpg"),
        position: "Hi-Lift Manufacturing Lead",
        linkedin: "https://www.linkedin.com/in/cristina-erskine-734242290/",
        major: "Aerospace Engineering",
        minor: "Dance",
        year: "2026",
      },
      {
        name: "Matthew Wu",
        image: require("../img/members/matthew_wu.jpg"),
        position: "Hi-Lift Propulsion Lead",
        linkedin: "https://www.linkedin.com/in/matthew-wu-py/",
        major: "Aerospace Engineering",
        minor: "Climate & Meteorology",
        year: "2027",
      },
      {
        name: "Nathanial Zou Zhao",
        image: require("../img/members/nate_zou_zhao.jpg"),
        position: "Hi-Lift Mechanical Systems Director",
        linkedin: "https://www.linkedin.com/in/nathanial-zou-zhao/",
        major: "Mechanical Engineering",
        year: "2025",
      },
      {
        name: "Andrew Barreras",
        image: require("../img/members/andrew_barreras.jpg"),
        position: "Hi-Lift Fuselage and Payload Lead",
        linkedin: "https://www.linkedin.com/in/andrew-barreras-2aaab4308",
        major: "Mechanical Engineering",
        minor: "Electrical Engineering",
        year: "2025",
      },
      {
        name: "Zayden Boonstra",
        image: require("../img/members/placeholder.jpg"),
        position: "Hi-Lift Wing and Tail Lead",
        linkedin: "",
        major: "Mechanical Engineering",
        year: "2025",
      },
    ],
  },
  {
    name: "Advanced Class",
    list: [
      {
        name: "Michael Chen",
        image: require("../img/members/michael_chen.jpg"),
        position: "Advanced Class Chief Engineer",
        linkedin: "https://www.linkedin.com/in/michael-j-chen",
        major: "Aerospace Engineering",
        year: "2025",
      },
      {
        name: "Thomas Sheridan",
        image: require("../img/members/tom_sheridan.jpg"),
        position: "Advanced Class Propulsion & Aerodynamics Lead",
        linkedin: "https://www.linkedin.com/in/thomasssheridanr/",
        major: "Aerospace Engineering",
        minor: "Materials Science Engineering",
        year: "2026",
      },
      {
        name: "James Gold",
        image: require("../img/members/james_gold.jpg"),
        position: "Advanced Class Manufacturing Lead",
        linkedin: "https://www.linkedin.com/in/james-f-gold/",
        major: "Aerospace Engineering & Double Bass Performance",
        year: "2025",
      },
      {
        name: "Viktor Lewis",
        image: require("../img/members/placeholder.jpg"),
        position: "Advanced Mechanical Systems Director",
        linkedin: "",
        major: "Mechanical Engineering",
        year: "2025",
      },
      {
        name: "Jake Leopold",
        image: require("../img/members/placeholder.jpg"),
        position: "Advanced Main Aircraft Lead",
        linkedin: "",
        major: "Aerospace Engineering",
        minor: "Materials Science Engineering",
        year: "2024",
      },
      {
        name: "Santiago Ramos-Assam",
        image: require("../img/members/santiago_ramos-assam.jpg"),
        position: "Advanced Class Secondary Aircraft Structures Lead",
        linkedin: "https://www.linkedin.com/in/santiago-ramosassam/",
        major: "Aerospace Engineering",
        year: "2025",
      },
      {
        name: "Nihal Sekhon",
        image: require("../img/members/placeholder.jpg"),
        position: "Avionics Systems Director",
        linkedin: "",
        major: "Aerospace Engineering",
        minor: "Computer Science & Electrical Engineering",
        year: "2025",
      },
      {
        name: "Holden Halucha",
        image: require("../img/members/placeholder.jpg"),
        position: "Advanced Class Hardware Lead",
        linkedin: "",
        major: "Computer Engineering",
        year: "2026",
      },
      {
        name: "Ishan Kumar",
        image: require("../img/members/ishan_kumar.jpg"),
        position: "Advanced Class Software Lead",
        linkedin: "https://www.linkedin.com/in/ishan-kumar-21b0612b9/",
        major: "Computer Engineering",
        year: "2027",
      },
    ],
  },
  {
    name: "Autonomous",
    list: [
      {
        name: "Abhineet Repala",
        image: require("../img/members/abhineet_repala.jpg"),
        position: "Autonomous Chief Engineer & Main Aircraft Lead",
        linkedin: "https://www.linkedin.com/in/abhineet-repala-578563263/",
        major: "Mechanical Engineering",
        year: "2026",
      },
      {
        name: "Rishi Shetty",
        image: require("../img/members/rishi_shetty.jpg"),
        position: "Autonomous Propulsion Lead",
        linkedin: "https://www.linkedin.com/in/rishi-shetty/",
        major: "Aerospace Engineering",
        minor: "Computer Science",
        year: "2026",
      },
      {
        name: "Armaan Kamat",
        image: require("../img/members/armaan_kamat.jpg"),
        position: "Autonomous Aerodynamics Lead",
        linkedin: "https://www.linkedin.com/in/armaankamat/",
        major: "Aerospace Engineering",
        year: "2027",
      },
      {
        name: "Ranald Lam",
        image: require("../img/members/placeholder.jpg"),
        position: "Autonomous Manufacturing Lead",
        linkedin: "https://www.linkedin.com/in/ranald-lam-/",
        major: "Mechanical Engineering",
        year: "2026",
      },
      {
        name: "Jerry Mao",
        image: require("../img/members/jerry_mao.jpg"),
        position: "Autonomous Mechanical Systems Director",
        linkedin: "https://www.linkedin.com/in/jerry-mao-174186295",
        major: "Mechanical Engineering",
        year: "2026",
      },
      // {
      //   name: "",
      //   image: require("../img/members/.jpg"),
      //   position: "Autonomous Payload and Mechanisms Lead",
      //   linkedin: "https://www.linkedin.com/in//",
      //   major: "",
      //   year: "",
      // },
      {
        name: "Teja Koduru",
        image: require("../img/members/teja_koduru.jpg"),
        position: "Autonomous Systems Director",
        linkedin: "https://linkedin.com/in/tskoduru",
        major: "Aerospace Engineering",
        minor: "Computer Science & Electrical Engineering",
        year: "2026",
      },
      {
        name: "Shameel Stark",
        image: require("../img/members/shameel_stark.jpg"),
        position: "Autonomous Hardware Lead",
        linkedin: "https://www.linkedin.com/in/shameel-stark-709432237/",
        major: "Computer Engineering",
        year: "2026",
      },
      {
        name: "Cindy Yang",
        image: require("../img/members/placeholder.jpg"),
        position: "Autonomous Flight Systems Lead",
        linkedin: "https://www.linkedin.com/in/2023cyang/",
        major: "Robotics",
        minor: "Computer Science & Electrical Engineering",
        year: "2026",
      },
      {
        name: "Marcus Chung",
        image: require("../img/members/placeholder.jpg"),
        position: "Autonomous Computer Vision Lead",
        linkedin: "https://www.linkedin.com/in/marcus-chung-421167272/",
        major: "Masters in Computer Science",
        year: "2025",
      },
    ],
  },
  {
    name: "Chairs",
    list: [
      {
        name: "Hallen Ji",
        image: require("../img/members/hallen_ji.jpg"),
        position: "Truck Chair",
        linkedin: "https://www.linkedin.com/in/hallen-ji/",
        major: "Aerospace Engineering",
        year: "2025",
      },
      {
        name: "Alyssa Cheslek",
        image: require("../img/members/alyssa_cheslek.jpg"),
        position: "Business Chair",
        linkedin: "https://www.linkedin.com/in/alyssa-cheslek",
        major: "Aerospace Engineering",
        minor: "Electrical Engineering",
        year: "2026",
      },
      {
        name: "Eva Manabat",
        image: require("../img/members/eva_manabat.jpg"),
        position: "Webmaster & Social Media Chair",
        linkedin: "https://www.linkedin.com/in/evamanabat/",
        major: "Computer Engineering",
        year: "2026",
      },
      {
        name: "Diego Prado",
        image: require("../img/members/diego_prado.jpg"),
        position: "SaFE Chair",
        linkedin: "",
        major: "Aerospace Engineering",
        year: "2026",
      },
      {
        name: "Emma Lawson",
        image: require("../img/members/placeholder.jpg"),
        position: "Graphic Design Chair",
        linkedin: "",
        major: "Computer Science",
        year: "2025",
      },
    ],
  },
];

export default members;
