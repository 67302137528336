import React from "react";
import { Helmet } from "react-helmet-async";

import elgan404 from "../img/elgan404.jpg";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page Not Found | M-Fly</title>
      </Helmet>
      <div className="Contact">
        <div className="white-div">
          <h1 className="text-center">Page Not Found</h1>
          <p className="text-center">
            <img
              src={elgan404}
              alt="Elgan 404"
              style={{ maxWidth: "100%", maxHeight: 300 }}
            />
          </p>
          <h2 className="text-center">
            Looks like you flew into some turbulence and got lost!
          </h2>
        </div>
      </div>
    </>
  );
}
