import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import AOS from "aos";

import "../css/Aircraft.scss";
import AircraftStat from "../components/AircraftStat";
import m16 from "../img/aircraft/m16.jpg";
import mx9 from "../img/aircraft/mx9.jpg";
import mat4 from "../img/aircraft/mat4.jpg";

export default function Aircraft() {
  useEffect(() => {
    AOS.init({
      easing: "ease",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Aircraft | M-Fly</title>
      </Helmet>
      <div className="Aircraft">
        <div className="white-div">
          <div className="clearfix">
            <img
              src={m16}
              alt="M-16"
              className="img float-left"
              data-aos="fade-right"
              data-aos-easing="ease"
              data-aos-duration="2000"
            />

            <h1 className="aircraft-name">M-16</h1>
            <p>
              The M-16 is a high lift aircraft for the 2023 SAE Aero Design East Competition. 
              The aircraft carries a payload of 20 pounds loaded along the length of the fuselage. 
              The aircraft is almost exclusively built from wood and utilizes a carbon fiber 
              propeller and landing gear. After our competition, the M-16 placed 12th out of 32 
              teams overall in the 2024 SAE Aero Design East Competition, winning an award for the 
              best design in the US (2nd overall).
            </p>
          </div>
          <div className="stats">
            <AircraftStat value="19 lbs" label="empty weight" />
            <AircraftStat value="40 lbs" label="max takeoff weight" />
            <AircraftStat value="15 ft" label="wingspan" />
            <AircraftStat value="35.4 ft/s" label="max flight speed" />
          </div>
        </div>
        <div className="blue-div">
          <div className="clearfix ox">
            <img
              src={mx9}
              alt="MX-8"
              className="img float-right"
              data-aos="fade-left"
              data-aos-easing="ease"
              data-aos-duration="2000"
            />

            <h1 className="aircraft-name">MX-9</h1>
            <p>
              The MX-9 "Phoenix” is the 9th M-Fly Advanced Class Aircraft and competed in 
              the 2024 SAE Aero Design Competition. It is a blended wing body configuration 
              utilizing a carbon fiber fuselage to achieve precise airfoil geometry and 
              maximize overall performance. It is powered by a nose mounted 750 Watt-limited
              motor capable of providing sufficient thrust to carry 8 lbs of water payload 
              along with a 1 lb secondary parasite aircraft mounted underneath the fuselage.
              On board it contains a data acquisition system along with an FPV camera system 
              capable of detecting ground targets and releasing the secondary aircraft to 
              fly autonomously towards them. The MX-9's design placed best in the nation, 
              3rd in oral report, and 3rd overall out of over 15 teams from across the globe.
            </p>
          </div>
          <div className="stats">
            <AircraftStat value="25 lbs" label="empty weight" />
            <AircraftStat value="34 lbs" label="max takeoff weight" />
            <AircraftStat value="10 ft" label="wingspan" />
            <AircraftStat value="~46 ft/s" label="max flight speed" />
          </div>
        </div>
        <div className="white-div">
          <div className="clearfix">
            <img
              src={mat4}
              alt="MAT-4"
              className="img float-left"
              data-aos="fade-right"
              data-aos-easing="ease"
              data-aos-duration="2000"
            />

            <h1 className="aircraft-name">MAT-4</h1>
            <p>
            The MAT-4 is a fully autonomous aircraft designed to compete in the Student
            Unmanned Aerial Systems (SUAS) Competition. Its goal is to autonomously fly 
            through a series of waypoints while avoiding dynamic and static obstacles, 
            detect targets on the ground using computer vision, and drop payloads safely 
            to those targets. Made out of carbon fiber composites, wood, ultracote, and 
            3D printed parts, the MAT-4 is equipped with the Pixhawk 6C as its flight 
            controller and the Jetson Nano as its onboard computer. The MAT-4 had several 
            successful autonomous test flights, and the team is currently iterating on 
            its design for future SUAS competitions.
            </p>
          </div>
          <div className="stats">
            <AircraftStat value="30 lbs" label="empty weight" />
            <AircraftStat value="40 lbs" label="max takeoff weight" />
            <AircraftStat value="11.25 ft" label="wingspan" />
            <AircraftStat value="~53 ft/s" label="max flight speed" />
          </div>
        </div>
      </div>
    </>
  );
}
