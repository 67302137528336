import React from "react";
import { Helmet } from "react-helmet-async";

import "../css/Members.scss";
import members from "../components/MembersList";
import placeholder from "../img/members/placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function Members() {
  console.log(members);
  return (
    <>
      <Helmet>
        <title>Members | M-Fly</title>
      </Helmet>
      <div className="Members">
        {members.map((group) => (
          <div className="team-group" key={group.name}>
            <div className="text-center">
              <h1>{group.name}</h1>
            </div>
            <ul className="card-container">
              {group.list.map((member) => (
                <li className="card-item" key={member.name}>
                  <div className="card">
                    {member.linkedin ? (
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          src={member.image ?? placeholder}
                          alt={member.name}
                          className="card-image"
                        />
                      </a>
                    ) : (
                      <img
                        src={member.image ?? placeholder}
                        alt={member.name}
                        className="card-image"
                      />
                    )}
                    <h4 className="card-title">
                      {member.linkedin ? (
                         <>
                          <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            {member.name}
                            <FontAwesomeIcon 
                              icon={faLinkedin} 
                              style={{ 
                                marginLeft: '0.4em', 
                                color: 'lightsteelblue'
                              }} 
                            />
                          </a>
                        </>
                      ) : (
                        member.name
                      )}
                    </h4>
                    <p className="card-text">
                      <span className="card-subtitle">{member.position}</span>
                      {member.major && member.year && (
                        <span>
                          {member.major}, {member.year}
                        </span>
                      )}
                      {/* <span className="card-subtitle">{member.minor}</span> */}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}
